import { AES, enc } from "crypto-js";
import { isEmpty, isObject } from "lodash";
import { saveData, deleteData, getData } from "@/core/services/local.service";

const ID_TOKEN_KEY = "access_token";
const ID_TOKEN_TYPE = "token_type";
const ID_TOKEN_EXPIRY = "expires_at";
const ID_CURRENT_USER = "_btusr_tmp";
const ID_PERMISSIONS = "_btusr_permission_tmp";
const ID_MENU_LIST = "_btcsr_tmp";
const ID_PERMISSION_LIST = "_btpsr_tmp";
const ID_CLIENT_DETAILS = "_btcld_tmp";
const ENCRYPTION_KEY = process.env.VUE_APP_ENCRYPTION_KEY;

const destroyAuth = () => {
	deleteData(ID_TOKEN_KEY);
	deleteData(ID_TOKEN_TYPE);
	deleteData(ID_TOKEN_EXPIRY);
	deleteData(ID_CURRENT_USER);
	deleteData(ID_PERMISSIONS);
	deleteData(ID_MENU_LIST);
	deleteData(ID_PERMISSION_LIST);
	deleteData(ID_CLIENT_DETAILS);
};

export const saveLocalStorage = (key, data) => {
	try {
		if (isEmpty(data) === false && isObject(data)) {
			let encrypted = AES.encrypt(JSON.stringify(data), ENCRYPTION_KEY).toString();
			saveData(key, encrypted);
		}
	} catch (error) {
		console.warn({ error });
	}
};

export const getLocalStorage = (key) => {
	try {
		let encrypted = getData(key);
		if (!encrypted) {
			return null;
		}
		let decrypted = AES.decrypt(encrypted, ENCRYPTION_KEY);
		return JSON.parse(decrypted.toString(enc.Utf8));
	} catch (error) {
		console.warn({ error });
	}
};

export const isAuthenticated = () => {
	return !!window.localStorage.getItem(ID_TOKEN_KEY);
};

export const getToken = () => {
	return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const getTokenType = () => {
	return window.localStorage.getItem(ID_TOKEN_TYPE);
};

export const getTokenExpiry = () => {
	return window.localStorage.getItem(ID_TOKEN_EXPIRY);
};

export const getCurrentUser = () => {
	return getLocalStorage(ID_CURRENT_USER);
};

export const getPermissions = () => {
	return getLocalStorage(ID_PERMISSION_LIST);
};

export const getMenuList = () => {
	return getLocalStorage(ID_MENU_LIST);
};

export const saveToken = ({ user, token_type, expires_at, access_token, role }) => {
	window.localStorage.setItem(ID_TOKEN_TYPE, token_type);
	window.localStorage.setItem(ID_TOKEN_KEY, access_token);
	window.localStorage.setItem(ID_TOKEN_EXPIRY, expires_at);
	saveLocalStorage(ID_CURRENT_USER, user);
	saveLocalStorage(ID_MENU_LIST, role.menu);
	saveLocalStorage(ID_PERMISSION_LIST, role.permissions);
};

export const destroyToken = () => {
	destroyAuth();
};

export default {
	getToken,
	getTokenType,
	getTokenExpiry,
	getCurrentUser,
	getMenuList,
	getPermissions,
	saveToken,
	saveLocalStorage,
	getLocalStorage,
	isAuthenticated,
	destroyToken,
};
