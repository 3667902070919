import ApiService from "@/core/services/api.service";
import { toSafeInteger, isArray } from "lodash";

// action types

// mutation types
export const ERROR = "error";
export const MESSAGE = "message";
export const SET_ERROR = "setError";
export const SET_MESSAGE = "setMessage";
export const GET_PROJUCT_DROPDOWN = "getProjectDropdown";
export const SET_PROJECT_DROPDOWN = "setProjectDropdown";
export const SET_EXPENSE_FILTER = "setExpenseFilter";

const state = {
	errors: [],
	messages: [],
	projects_dropdown: [],
	expenseFilter: [],
};

const getters = {
	errors(state) {
		return state.errors;
	},
	messages(state) {
		return state.messages;
	},
};

const actions = {
	[ERROR](context, param) {
		context.commit(SET_ERROR, param);
	},
	[MESSAGE](context, param) {
		context.commit(SET_MESSAGE, param);
	},
	[GET_PROJUCT_DROPDOWN](context, params) {
		return new Promise((resolve, reject) => {
			ApiService.setHeader();
			ApiService.query(`listing/all`, params)
				.then(({ data }) => {
					context.commit(SET_PROJECT_DROPDOWN, data.list);
					resolve(data);
				})
				.catch(({ response }) => {
					context.commit(SET_ERROR, response.data.errors);
					reject(response);
				});
		});
	},
};

const mutations = {
	[SET_ERROR](state, error) {
		if (error && isArray(error)) {
			state.errors = error.map((row) => {
				row.timeout = toSafeInteger(process.env.VUE_APP_MESSAGE_TIMEOUT);
				return row;
			});
		} else {
			state.errors = [];
		}
	},
	[SET_MESSAGE](state, message) {
		if (message && isArray(message)) {
			state.messages = message.map((row) => {
				row.timeout = toSafeInteger(process.env.VUE_APP_MESSAGE_TIMEOUT);
				return row;
			});
		} else {
			state.messages = [];
		}
	},
	[SET_PROJECT_DROPDOWN](state, data) {
		state.projects_dropdown = data;
	},
	[SET_EXPENSE_FILTER](state, data) {
		state.expenseFilter = data;
	},
};

export default {
	state,
	actions,
	mutations,
	getters,
};
