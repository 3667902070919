import ApiService from "@/core/services/api.service";
// import { SET_ERROR } from "@/core/services/store/common.module";
// action types
export const GET_PROFORMA_BY_PROJECT = "getProformaByProject";
export const GET_PROFORMA_BY_PROJECT_MILESTONE = "getProformaByProjectMilestone";
export const UPDATE_PROFORMA = "updatePROFORMA";
export const CREATE_PROFORMA = "CreateProforma";
export const DELETE_PROFORMA = "deleteProforma";
export const UPDATE_PROFORMA_STATUS = "updateProformaStatus";
export const UPDATE_QUOTATIONS_LINE_ITEMS = "updateQuotationLineItems";
export const SET_QUOTATIONS_LINE_ITEM = "setMilestoneLineItem";
export const GET_MILESTONE_DELIVERABLE = "getMilestoneDeliverable";
export const UPDATE_MILESTONE_DELIVERABLE = "updateMilestoneDeliverable";
export const UPDATE_MILESTONE_PROFORMA_STATUS = "updateMilestoneProformaStatus";
export const GET_PROFORMA_By_UUID = "getProformaByUUID";

// mutation types
export const SET_ERROR = "setError";
export const SET_PROFORMA = "setProforma";

const state = {
	proforma: [],
	quotation_line_items: [],
};

const getters = {
	getProforma(state) {
		return state.proforma;
	},
	getQuotationsLineItems(state) {
		return state.quotation_line_items;
	},
};

const actions = {
	[GET_PROFORMA_BY_PROJECT](context, { uuid, params }) {
		return new Promise((resolve, reject) => {
			ApiService.setHeader();
			ApiService.query(`project/${uuid}/option/proforma`, params)
				.then(({ data }) => {
					context.commit(SET_PROFORMA, data);
					resolve(data);
				})
				.catch(({ response }) => {
					context.commit(SET_ERROR, response.data.errors);
					reject(response);
				});
		});
	},
	[GET_PROFORMA_BY_PROJECT_MILESTONE](context, uuid) {
		return new Promise((resolve, reject) => {
			ApiService.setHeader();
			ApiService.get(`project-milestone/${uuid}/option/proforma`)
				.then(({ data }) => {
					context.commit(SET_PROFORMA, data);
					resolve(data);
				})
				.catch(({ response }) => {
					context.commit(SET_ERROR, response.data.errors);
					reject(response);
				});
		});
	},
	[GET_PROFORMA_By_UUID](context, uuid) {
		return new Promise((resolve, reject) => {
			ApiService.setHeader();
			ApiService.get(`performa-invoice/${uuid}`)
				.then(({ data }) => {
					// context.commit(SET_PROFORMA, data);
					resolve(data);
				})
				.catch(({ response }) => {
					context.commit(SET_ERROR, response.data.errors);
					reject(response);
				});
		});
	},
	[CREATE_PROFORMA](context, params) {
		return new Promise((resolve, reject) => {
			ApiService.setHeader();
			ApiService.post(`performa-invoice/project-milestone`, params)
				.then(({ data }) => {
					// context.commit(SET_PROFORMA, data);
					resolve(data);
				})
				.catch(({ response }) => {
					context.commit(SET_ERROR, response.data.errors);
					reject(response);
				});
		});
	},
	[UPDATE_PROFORMA](context, { uuid, params }) {
		return new Promise((resolve, reject) => {
			ApiService.setHeader();
			ApiService.put(`performa-invoice/${uuid}`, params)
				.then(({ data }) => {
					// context.commit(SET_PROFORMA, data);
					resolve(data);
				})
				.catch(({ response }) => {
					context.commit(SET_ERROR, response.data.errors);
					reject(response);
				});
		});
	},
	[DELETE_PROFORMA](context, params) {
		return new Promise((resolve, reject) => {
			ApiService.setHeader();
			ApiService.patch(`performa-invoice`, params)
				.then(({ data }) => {
					// context.commit(SET_PROFORMA, data);
					resolve(data);
				})
				.catch(({ response }) => {
					context.commit(SET_ERROR, response.data.errors);
					reject(response);
				});
		});
	},
	[UPDATE_PROFORMA_STATUS](context, { action, uuid, remark = null }) {
		let params = null;
		if (!remark) {
			params = {
				action: action,
				selected: [uuid],
			};
		} else {
			params = {
				action: action,
				selected: [uuid],
				remark: remark,
			};
		}

		return new Promise((resolve, reject) => {
			ApiService.setHeader();
			ApiService.patch(`performa-invoice/status/update`, params)
				.then(({ data }) => {
					resolve(data);
				})
				.catch(({ response }) => {
					context.commit(SET_ERROR, response.data.errors);
					reject(response);
				});
		});
	},
	[UPDATE_MILESTONE_PROFORMA_STATUS](context, params) {
		return new Promise((resolve, reject) => {
			ApiService.setHeader();
			ApiService.patch(`performa-invoice/status/update`, params)
				.then(({ data }) => {
					resolve(data);
				})
				.catch(({ response }) => {
					context.commit(SET_ERROR, response.data.errors);
					reject(response);
				});
		});
	},
	[UPDATE_QUOTATIONS_LINE_ITEMS](context, data) {
		context.commit(SET_QUOTATIONS_LINE_ITEM, data);
	},
	[UPDATE_MILESTONE_DELIVERABLE](context, { uuid, params }) {
		return new Promise((resolve, reject) => {
			ApiService.setHeader();
			ApiService.post(`project-milestone/${uuid}/deliverable/save`, params)
				.then(({ data }) => {
					resolve(data);
				})
				.catch(({ response }) => {
					context.commit(SET_ERROR, response.data.errors);
					reject(response);
				});
		});
	},
	[GET_MILESTONE_DELIVERABLE](context, { uuid, params }) {
		return new Promise((resolve, reject) => {
			ApiService.setHeader();
			ApiService.query(`project-milestone/${uuid}/option/deliverable`, params)
				.then(({ data }) => {
					resolve(data);
				})
				.catch(({ response }) => {
					context.commit(SET_ERROR, response.data.errors);
					reject(response);
				});
		});
	},
};

const mutations = {
	[SET_PROFORMA](state, data) {
		state.proforma = data.proformas;
	},
	[SET_QUOTATIONS_LINE_ITEM](state, data) {
		state.quotation_line_items = data;
	},
};

export default {
	state,
	actions,
	mutations,
	getters,
};
