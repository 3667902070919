import ApiService from "@/core/services/api.service";

// action types
export const GET_CONTACT = "getContact";
export const CREATE_CUSTOMER = "createCustomer";
export const CREATE_SUPPLIER = "createVendor";
export const UPDATE_CONTACT = "updateContact";
export const DELETE_CONTACT = "deleteContact";

// mutation types
export const SET_CONTACT = "setContact";
export const SET_ERROR = "setError";

const state = {
	errors: [],
	customer: {},
};

const getters = {
	customer(state) {
		return state.customer;
	},
};

const actions = {
	[GET_CONTACT](context, id) {
		return new Promise((resolve, reject) => {
			ApiService.setHeader();
			ApiService.get(`contact/${id}`)
				.then(({ data }) => {
					context.commit(SET_CONTACT, data);
					resolve(data);
				})
				.catch(({ response }) => {
					context.commit(SET_ERROR, response.data.errors);
					reject(response);
				});
		});
	},
	[CREATE_CUSTOMER](context, params) {
		return new Promise((resolve, reject) => {
			ApiService.setHeader();
			ApiService.post("contact/customer", params)
				.then(({ data }) => {
					context.commit(SET_CONTACT, data);
					resolve(data);
				})
				.catch(({ response }) => {
					context.commit(SET_ERROR, response.data.errors);
					reject(response);
				});
		});
	},
	[UPDATE_CONTACT](context, { id, ...params }) {
		return new Promise((resolve, reject) => {
			ApiService.setHeader();
			ApiService.patch(`contact/${id}`, params)
				.then(({ data }) => {
					context.commit(SET_CONTACT, data);
					resolve(data);
				})
				.catch(({ response }) => {
					context.commit(SET_ERROR, response.data.errors);
					reject(response);
				});
		});
	},
	[DELETE_CONTACT](context, uuid) {
		return new Promise((resolve, reject) => {
			ApiService.setHeader();
			ApiService.delete(`contact/${uuid}`)
				.then(({ data }) => {
					context.commit(SET_CONTACT, {});
					resolve(data);
				})
				.catch(({ response }) => {
					context.commit(SET_ERROR, response.data.errors);
					reject(response);
				});
		});
	},
};

const mutations = {
	[SET_CONTACT](state, data) {
		state.customer = data;
	},
	[SET_ERROR](state, error) {
		state.errors = error;
	},
};

export default {
	state,
	actions,
	mutations,
	getters,
};
