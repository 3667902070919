import objectPath from "object-path";
import merge from "deepmerge";
import config from "@/core/config/layout.config.json";
import ApiService from "@/core/services/api.service";

// action types
export const SET_LOCAL_DB = "setLocalDB";
export const SET_TERMS_AND_CONDITION = "setTermsAndCondition";
export const SET_LAYOUT_CONFIG = "setLayoutConfig";
export const CART_COUNT = "cartCount";
export const RESET_LAYOUT_CONFIG = "resetLayoutConfig";
export const OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig";
export const OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig";
export const SET_BARCODE_SETTING = "getBarcodeSetting";
export const SET_ERROR = "setError";

// mutation types

export default {
	state: {
		config: config,
		initial: config,
		localDB: [],
		localDBInitialized: false,
		cartCount: 0,
		termsAndConditions: [],
		barcode_setting: [],
	},
	getters: {
		/**
		 * Get config from layout config
		 * @param state
		 * @returns {function(path, defaultValue): *}
		 */
		layoutConfig: (state) => (path, defaultValue) => {
			return objectPath.get(state.config, path, defaultValue);
		},
		/**
		 * Get data from local db
		 * @param state
		 * @returns {function(path, defaultValue): *}
		 */
		localDB: (state) => (path, defaultValue) => {
			return objectPath.get(state.localDB, path, defaultValue);
		},

		termsAndConditions: (state) => {
			return state.termsAndConditions;
		},
		barcode_setting: (state) => {
			return state.barcode_setting;
		},
		isLocalDBInitialized: (state) => () => {
			return state.localDBInitialized;
		},

		cartCount: (state) => () => {
			return state.cartCount || 0;
		},
	},
	actions: {
		/**
		 * Set and replace the local db
		 * @param state
		 * @param payload
		 */
		[SET_TERMS_AND_CONDITION](state, type) {
			return new Promise((resolve) => {
				ApiService.get(`termconditions/module/${type}`)
					.then(({ data }) => {
						state.commit(SET_TERMS_AND_CONDITION, data);
						resolve(data);
					})
					.catch(({ response }) => {
						state.commit(SET_ERROR, response.data.errors);
					});
			});
		},
		/**
		 * Set and replace the barcode
		 * @param state
		 * @param payload
		 */
		[SET_BARCODE_SETTING](state) {
			return new Promise((resolve) => {
				ApiService.get(`setting/barcode`)
					.then(({ data }) => {
						state.commit(SET_BARCODE_SETTING, data);
						resolve(data);
					})
					.catch(({ response }) => {
						state.commit(SET_ERROR, response.data.errors);
					});
			});
		},
		/**
		 * Set and replace the local db
		 * @param state
		 * @param payload
		 */
		[CART_COUNT](state) {
			return new Promise((resolve) => {
				ApiService.setHeader();
				ApiService.get("cart/count")
					.then(({ data }) => {
						state.commit(CART_COUNT, data);
						resolve(data);
					})
					.catch(({ response }) => {
						state.commit(SET_ERROR, response.data.errors);
					});
			});
		},

		/**
		 * Set and replace the local db
		 * @param state
		 * @param payload
		 */
		[SET_LOCAL_DB](state) {
			return new Promise((resolve) => {
				ApiService.get("get-config")
					.then(({ data }) => {
						state.commit(SET_LOCAL_DB, data);
						resolve(data);
					})
					.catch(({ response }) => {
						state.commit(SET_ERROR, response.data.errors);
					});
			});
		},

		/**
		 * Set and replace the whole config
		 * @param state
		 * @param payload
		 */
		[SET_LAYOUT_CONFIG](state, payload) {
			state.commit(SET_LAYOUT_CONFIG, payload);
		},

		/**
		 * Reset the config at the initial state
		 * @param state
		 */
		[RESET_LAYOUT_CONFIG](state) {
			state.commit(RESET_LAYOUT_CONFIG);
		},

		/**
		 * Reset the config using saved config in the cache by the layout builder
		 * @param state
		 */
		[OVERRIDE_LAYOUT_CONFIG](state) {
			state.commit(OVERRIDE_LAYOUT_CONFIG);
		},

		/**
		 * Override config by page level
		 * @param state
		 * @param payload
		 */
		[OVERRIDE_PAGE_LAYOUT_CONFIG](state, payload) {
			state.commit(OVERRIDE_PAGE_LAYOUT_CONFIG, payload);
		},
	},
	mutations: {
		[SET_TERMS_AND_CONDITION](state, payload) {
			state.termsAndConditions = payload;
		},
		[SET_BARCODE_SETTING](state, payload) {
			state.barcode_setting = payload;
		},
		[SET_LOCAL_DB](state, payload) {
			state.localDB = payload;
			state.localDBInitialized = true;
		},
		[CART_COUNT](state, payload) {
			state.cartCount = payload;
		},
		[SET_LAYOUT_CONFIG](state, payload) {
			state.config = payload;
		},
		[RESET_LAYOUT_CONFIG](state) {
			state.config = Object.assign({}, state.initial);
		},
		[OVERRIDE_LAYOUT_CONFIG](state) {
			state.config = state.initial = Object.assign(
				{},
				state.initial,
				JSON.parse(localStorage.getItem("config"))
			);
		},
		[OVERRIDE_PAGE_LAYOUT_CONFIG](state, payload) {
			state.config = merge(state.config, payload);
		},
	},
};
